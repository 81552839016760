<!-- App.vue -->
<template>
  <BaseLayout :coins="coins" :timeLeft="timeLeft" @update-coins="updateCoins" @update-timeLeft="updateTimeLeft">
    <router-view :coins="coins" :timeLeft="timeLeft" @update-coins="updateCoins" @update-timeLeft="updateTimeLeft" />
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue';

export default {
  data() {
    return {
      coins: 0,
      timeLeft: 30
    };
  },
  methods: {
    updateCoins(newCoins) {
      this.coins = newCoins;
    },
    updateTimeLeft(newTimeLeft) {
      this.timeLeft = newTimeLeft;
    }
  },
  components: {
    BaseLayout
  }
};
</script>

<style>
body {
  padding: 0;
  user-select: none;
}

#app {
  font-family: 'Arial', sans-serif;
  background-color: #F2F3F4;
}
</style>
